<template>
  <TheBlock title="Notre expertise">
    <b-row>
      <b-col xs md="6" lg v-for="(e, index) in expertises" v-bind:key="index">
        <ExpertiseBlock :title="e.title" :sub_title="e.sub_title" :icon="e.icon"/>
      </b-col>
    </b-row>
    <b-card-group deck class="mt-5 px-3">
      <b-card v-for="card in cards" v-bind:key="card.id" no-body bg-variant="light" text-variant="dark">
        <b-card-body>
          <h3>{{ card.title }}</h3>
          <Brands :list="card.brands">
            <div v-if="card.exoscale" class="border-top my-2 text-center pt-3">
              <b-img-lazy fluid :src="require('@/assets/images/brands/exoscale-partner.png')" alt="Exoscale"
                          width="200"/>
            </div>
          </Brands>
        </b-card-body>
      </b-card>
    </b-card-group>
  </TheBlock>
</template>

<script>
import ExpertiseBlock from "./Expertise/ExpertiseBlock";
import TheBlock from "./_common/TheBlock";
import Brands from "./Expertise/Brands";

export default {
  name: "Expertise",
  components: {Brands, TheBlock, ExpertiseBlock},
  data() {
    return {
      show: false,
      partners: [
        {id: 'aws', name: 'AWS'},
        {id: 'circleci', name: 'CircleCI'},
        {id: 'github', name: 'Github', width: 50},
        {id: 'gitlab', name: 'Gitlab'},
        {id: 'google-cloud', name: 'Google Cloud'},
        {id: 'infomaniak', name: 'Infomaniak'},
        {id: 'scaleway', name: 'Scaleway'},
      ],
      hosting: [
        {id: 'ansible', name: 'Ansible'},
        {id: 'debian', name: 'Debian'},
        {id: 'freebsd', name: 'FreeBSD'},
        {id: 'grafana', name: 'Grafana'},
        {id: 'haproxy', name: 'HAProxy'},
        {id: 'kubernetes', name: 'Kubernetes'},
        {id: 'lets-encrypt', name: "Let's Encrypt"},
        {id: 'mariadb', name: 'MariaDB'},
        {id: 'mysql', name: 'MySQL'},
        {id: 'nginx', name: 'Nginx'},
        {id: 'prometheus', name: 'Prometheus'},
        {id: 'proxmox', name: 'Proxmox'},
        {id: 'redis', name: 'Redis'},
        {id: 'terraform', name: 'Terraform'},
        {id: 'ubuntu', name: 'Ubuntu'},
      ],
      dev: [
        {id: 'bash', name: 'Bash'},
        {id: 'celery', name: 'Celery'},
        {id: 'docker', name: 'Docker'},
        {id: 'fastapi', name: 'FastAPI'},
        {id: 'nodejs', name: 'Node JS'},
        {id: 'phalcon', name: 'Phalcon'},
        {id: 'php', name: 'PHP'},
        {id: 'python', name: 'Python'},
        {id: 'symfony', name: 'Symfony'},
        {id: 'vuejs', name: 'VueJS'},
      ]
    }
  },
  mounted() {
    this.show = true;
  },
  computed: {
    cards() {
      return [
        {title: 'Hébergement', brands: this.hosting},
        {title: 'Développement', brands: this.dev},
        {title: 'Partenaires', brands: this.partners, exoscale: true},
      ]
    },
    expertises() {
      return [
        {
          title: 'Devops',
          sub_title: "Chaque projet que nous menons suit une approche Devops. Nous accompagnons aussi les entreprises sur des de projets de CI/CD, conteneurisation des applications, agilité des processus, conseils en outillage... etc.",
          icon: "rocket"
        },
        {
          title: 'Infrastructure',
          sub_title: "Vous pouvez nous confier l'infogérence de vos serveurs pour vos sites web. Nous nous occupons de la sécurité, de la haute disponibilité et la performance. Supervision et intervention 24h/24.",
          icon: "server"
        },
        {
          title: 'Stratégie',
          sub_title: "Pour vos projets, nous proposons des solutions de direction technique. De la gestion de projet aux ressources humaines en passant par le lead technique, nous vous accompagnons toutes les phases de croissance.",
          icon: "chess-king"
        },
        {
          title: 'Web',
          sub_title: "Nous vous accompagnons sur vos projets web ! Nous sommes experts en développement back avec les frameworks PHP Symfony et Phalcon.",
          icon: "code"
        },
      ];
    }
  }
}
</script>
